import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { add, remove_size_id } from '../redux/Slice/CartSlice';
import {toast} from "react-hot-toast";
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
export default function DetailProduct({ item,onClose }) {
  const {cart} = useSelector( (state) => state);
  const [size, setSize] = useState(Object.keys(item.stock)[0])
  
  const sizes = Object.keys(item.stock);

   const dispatch = useDispatch();

  const addToCart = () => {
      if (!size) {
         toast.error("Выберите размер");
         return;
      }
      const item_add = {...item, size};
      // item_add.id = item.id + size;
    dispatch(add(item_add));
    toast.success("Добавлен в корзину");
}

   const removeFromCart = (item_del) => {
      dispatch(remove_size_id(item_del));
      toast.error("Удален из корзины");
   }
   const createMarkup = (dirty) => {
      return { __html: dirty };
   }


 
  return (
    

    <div 
      style={{
          top:0, left:0, 
          width: '100vw', 
          height: '100vh', 
          backgroundColor: '#fff',
          overflowY: 'scroll'
        }}
      className="fixed z-10 ">
      
      <section className="screen-2">
      <div className="container-screen">
         <div className="img-container ">
            {/* <img className="img-container__img" src="../images/img4.jpg" alt=""/> */}
            <Swiper pagination={{
                     type: 'fraction',
                  }} navigation={true} modules={[Pagination,Navigation]} className="position-initial z-0 img-container__img" >
                  
                     {
                        item.images_list.map((image) => (
                            <SwiperSlide key={image}>
                              {/* <img className="carusel-my" src={image} alt=""/> */}
                              <div className='coursel-div-list ' 
                                    style={{ backgroundImage: `url(${image})`}}>
                                </div>
                              </SwiperSlide>
                        ))
                    }
            </Swiper>

            <div className="back-btn cursor-pointer" onClick={()=>onClose()}>
              
              <svg style={{zIndex:999}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" color="white"><path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"></path></svg>
            </div>
            <NavLink  to="/cart"> 
              <div className="basket">
                <img src="../images/basket.svg" alt="корзина покупок"/>
                {
                  cart.length > 0 ? (
                     <div className='cartbullet'> {cart.length} </div>
                  ) : null
                }
                
                
              </div>
            </NavLink>
            {
               'no' in item.stock ? null : (
                  <div className="size-box">
                     <span className="size-box__title">Размер</span>
                     <div className="size-box_sizes">
                        {
                           sizes.map( (s) => (
                              <div 
                                 key={s}
                                 onClick={ () => setSize(s) }
                                 className={`size-box_sizes__item ${size === s ? 'size-box_sizes__item-active' : ''}`}
                              >
                                 {s.toUpperCase()}
                              </div>
                           ))
                        }
                     
                     </div>
                  </div>
               )
            }
            
         </div>
         <div className="product-wrapper">
            <div className="product_title">
               <div className="product_title__name">{item.name}</div>
               <div className="product_title__text">{item.prewiev_text}</div>
            </div>
            <div className="screen-separator"></div>
            <div className="product_description">
               <div 
                  className="product_description__text" 
                  dangerouslySetInnerHTML={createMarkup(item.description)}>
               </div>
            </div>
            <div className="screen-separator"></div>
            <div className="buy-block">
               <div className="buy-block__price">{item.price.toLocaleString('ru')} руб</div>
               <div className="buy-block_btns-wrapper">
                    {
                      cart.some( (p) => p.id === item.id) ?
                      (
                        <div>
                           <div className=' flex-col' >
                           <button
                                 className=' buy-block__btn-buy-more '
                                 onClick={addToCart}
                              >
                                 Добавить еще
                              </button>

                              <Link to={"/cart"}>
                                 <button className="buy-block__btn-buy" >
                                    Перейти в корзину
                                 </button>
                              </Link>

                             

                             

                           </div>
                           
                        </div>
                     ) :
                      (
                          <button
                              className=' buy-block__btn-buy '
                              onClick={addToCart}
                          >
                              Добавить в Корзину
                          </button>


                      )
                  }
      
      
                
               </div>
               
            </div>
            {
                      cart.some( (p) => p.id === item.id) ?

                (<div className='placeholder-cart'>
                  <p>В корзине сейчас</p>
                  {
                     cart.filter( (p) => p.id === item.id).map( (p,index) => 
                     {
                        return <div className='line-cart-card' key={`${p.id}${index}`}>

                           <button 
                                 className="small" 
                                 onClick={ () => removeFromCart(p) }
                           >
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                 <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                 <path d="M18.8499 9.14001L18.1999 19.21C18.0899 20.78 17.9999 22 15.2099 22H8.7899C5.9999 22 5.9099 20.78 5.7999 19.21L5.1499 9.14001" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                 <path d="M10.3301 16.5H13.6601" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                 <path d="M9.5 12.5H14.5" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                           </button>
                           <div>
                              <strong> {p.quantity} шт. </strong> {p.name} 
                           
                              {p.size === "no" ? null : (<span> | <strong>  размер {p.size.toUpperCase()}  </strong></span>)}
                           </div>
                           </div>
                     })
                  }
               </div>) : null}
          
         </div>
      </div>
   </section>

    </div>
  )
}